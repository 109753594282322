*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow: hidden;
  font-size: 1vw;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

body {
  /* line-height: 1.5; */
  font-family: freight-text-pro, serif;
  font-weight: 400;
  /* font-family: eb-garamond, serif; */
  background-color: #262626;
  cursor: default;
}

.container {
  width: 98vw;
  display: grid;
  grid-template-areas:
    "t t t t a"
    "p p p o b"
    "c d e f g"
    "x x h i j"
    "z z z k l"
    "s q r m n";
  /* grid-gap: 10px;
  padding: 10px; */
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: 1vw;
}

.placeHolder {
  font-family: raleway, sans-serif;
  font-weight: 400;
  font-size: 8rem;
  background-color: #262626;
  color: #f2f2f2;
}

.placeHolder.on {
  background-color: #f2f2f2;
  color: #262626;
}

#websiteTitle {
  grid-area: t;
  position: relative;
}

#websiteTitle span {
  font-size: 8rem;
  position: absolute;
  bottom: 0;
}

#poet {
  font-size: 6rem;
  grid-area: p;
}

#extra1 {
  grid-area: z;
  font-size: 6rem;
}

#extra2 {
  grid-area: x;
  font-size: 6rem;
}

#control {
  grid-area: n;
  font-size: 10rem;
  text-align: center;
  line-height: calc((100vh - 2vw) / 6 + 10px);
}

#time {
  grid-area: z;
  font-size: 2.5rem;
}

.title {
  font-style: italic;
  font-weight: 600;
  font-size: 1rem;
  height: 35px;
  text-align: left;
  visibility: hidden;
  background-color: #262626;
  color: #f2f2f2;
  padding-left: 0.5vw;
}

.poem.on {
  background-color: #262626;
  color: #f2f2f2;
}

.poem.off {
  background-color: #f2f2f2;
  color: #262626;
  /* background-color: forestgreen;
  color: khaki !important;
  border-radius: 5px; */
}

.poemText {
  background-color: #f2f2f2;
}

/* .poemText.start {
  height: 25px;
  width: 25px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
} */

.item {
  text-align: center;
  align-items: center;
  min-width: calc(98vw / 5);
  min-height: calc((100vh - 2vw) / 6);
  background-color: #f2f2f2;
}

.item.on {
  background-color: #262626;
}

.item.off {
  background-color: #f2f2f2;
}

.poem {
  width: 100%;
  height: calc((100vh - 2vw) / 6 - 35px);
  text-align: center;
  align-self: center;
  line-height: calc((100vh - 2vw) / 6 - 35px);
  font-size: 3rem;
  /* border-bottom: 2px solid rgba(0, 0, 0, 0.02); */
  background-color: #f2f2f2;
  cursor: pointer;
}

/* .poem.start:hover {
  border: 2px dashed #f2f2f2;
} */

#box1 {
  grid-area: a;
}
#box2 {
  grid-area: b;
}
#box3 {
  grid-area: c;
}
#box4 {
  grid-area: d;
}
#box5 {
  grid-area: e;
}
#box6 {
  grid-area: f;
}
#box7 {
  grid-area: g;
}
#box8 {
  grid-area: h;
}
#box9 {
  grid-area: i;
}
#box10 {
  grid-area: j;
}
#box11 {
  grid-area: k;
}
#box12 {
  grid-area: l;
}
#box13 {
  grid-area: m;
}
#box14 {
  grid-area: o;
}
#box15 {
  grid-area: s;
}
#box16 {
  grid-area: q;
}
#box17 {
  grid-area: r;
}
